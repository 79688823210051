<template>
  <section class="absolute w-full h-full flex flex-col justify-center text-center">
    <h2 class="uppercase text-xs leading-3">
      {{ error.statusCode }} <br />
      {{ error.message }}
    </h2>
    <nuxt-link class="text-xs leading-3 uppercase mt-5" to="/">Go to homepage</nuxt-link>
  </section>
</template>
<script>
export default {
  name: 'LayoutError',
  layout: 'empty',
  props: {
    error: {
      type: [Object, Error],
      default: () => ({ statusCode: 400, message: 'Not Found' })
    }
  }
};
</script>
